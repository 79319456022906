import { isEmpty } from 'lodash';
import { ref } from 'vue';

export function useErrors(){
    const errors = ref({});

    return {
        set(value){
            if (typeof value === 'object') {
                errors.value = value;
            } else {
                errors.value = {'form': ['Something went wrong. Please try again or contact customer support.']};
            }
        },

        setField(field, value) {
            errors.value[field] = value;
        },

        forget(field){
            if (typeof field === 'undefined') {
                errors.value = {};
            } else {
                delete errors.value[field];
            }
        },

        has(field){
            if (typeof field === 'undefined') {
                return ! isEmpty(errors.value);
            }

            return Object.prototype.hasOwnProperty.call(errors.value, field);
        },

        all(){
            return errors.value;
        },

        get(field){
            if (typeof field === 'undefined') {
                return this.all();
            }

            if (! this.has(field)) {
                return null;
            }

            if (! Array.isArray(errors.value[field])) {
                return errors.value[field];
            }

            return errors.value[field][0];
        },

        getErrorsArray(field){
            if (typeof field === 'undefined') {
                return this.all();
            }

            if (! this.has(field)) {
                return null;
            }

            return errors.value[field];
        },
    };
}
