import axios from 'axios';
import _ from 'lodash';
import { reactive } from 'vue';
import { useErrors } from './errors.js';
import { cloneDeep } from 'lodash';

export function useForm(fields = {}, params = {}, headers = {}) {
    let defaultFields = cloneDeep(fields);
    let defaultParams = cloneDeep(params);
    let defaultHeaders = cloneDeep(headers);

    const submit = (method, uri, params, headers) => {
        return new Promise((resolve, reject) => {
            form.busy = true;

            axios(uri, {
                method,
                data: form.fields,
                params: params,
                headers: {Accept: 'application/json', ...headers},
            }).then((response) => {
                form.success = /^2\d{2}$/.test(response.status);
                resolve(response);
            }).catch((error) => {
                console.error(error);
                form.errors.set(error.response.data.errors);
                reject(error);
            }).finally(() => (form.busy = false));
        });
    };

    const form = reactive({
        fields,
        params,
        headers,
        busy: false,
        success: false,
        errors: useErrors(),

        reset() {
            this.busy = false;
            this.success = false;
            this.errors.forget();
            this.fields = cloneDeep(defaultFields);
            this.params = cloneDeep(defaultParams);
            this.headers = cloneDeep(defaultHeaders);
        },

        set(field, value) {
            this.fields[field] = value;
        },

        unset(field) {
            delete this.fields[field];
        },

        toggle(field) {
            if (typeof this.fields[field] !== 'boolean') {
                throw `${field} is not a boolean`;
            }

            this.fields[field] = !this.fields[field];
        },

        get(uri) {
            return submit('get', uri, params, headers);
        },

        isClean() {
            return _.isEqual(this.fields, defaultFields) && _.isEqual(this.params, defaultParams);;
        },

        isDirty() {
            return !this.isClean();
        },

        post(uri) {
            return submit('post', uri, params, headers);
        },

        put(uri) {
            return submit('put', uri, params, headers);
        },

        patch(uri) {
            return submit('patch', uri, params, headers);
        },

        delete(uri) {
            return submit('delete', uri, params, headers);
        },

        submit,
    });

    return form;
}
